import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import { useStateAt, useStore, useDispatch, useListeners } from '@myrtille/react'
import uniqBy from 'unique-by'
import { getSimplifiedAddress } from '@peep/common'
import './addPeep.css'

const AddPeep = ({ map }) => {
  const store = useStore()
  const addingPeep = useStateAt('addingPeep')
  const [places, setPlaces] = useState([])
  const dispatch = useDispatch()
  const [selectedPlace, setSelectedPlace] = useState()
  const [buttonDisabled, setButtonDisabled] = useState(false)

  const onHide = () => {
    store.mutate(state => {
      state.addingPeep = undefined
    })
  }

  useEffect(() => {
    setPlaces([])
    if (!addingPeep) return

    const getPlaceDetail = placeId => new Promise((resolve, reject) => {
      const service = new window.google.maps.places.PlacesService(map)
      const request = {
        placeId,
        fields: ['name', 'address_components', 'geometry']
      }

      service.getDetails(request, (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          resolve(place)
        }
        reject(status)
      })
    })

    const geocoder = new window.google.maps.Geocoder()
    geocoder.geocode(
      {
        location: addingPeep,
      },
      async (results, status) => {
        if (status !== 'OK' || results.length < 1) return

        let preparedPlaces = await Promise.all(
          results
            .filter(({ types }) => types.includes('street_address') || types.includes('establishment'))
            .map(async (result) => {
              const address = getSimplifiedAddress(result)
              const isEstablishment = result.types.includes('establishment')

              let label = address
              let detail
              if (isEstablishment) {
                detail = await getPlaceDetail(result.place_id)
                label = detail.name
              }

              return {
                isEstablishment,
                label,
                place: {
                  address_components: detail ? detail.address_components : result.address_components,
                  address,
                  name: detail ? detail.name : undefined,
                },
                location: {
                  latitude: detail ? detail.geometry.location.lat() : result.geometry.location.lat(),
                  longitude: detail ? detail.geometry.location.lng() : result.geometry.location.lng(),
                },
              }
            })
        )

        preparedPlaces = uniqBy(preparedPlaces, 'label')
        preparedPlaces = preparedPlaces.sort((a, b) => (a.isEstablishment) ? -1 : b.isEstablishment ? 1 : 0)
        setPlaces(preparedPlaces)
        if (preparedPlaces.length > 0) setSelectedPlace(preparedPlaces[0])
      },
    )
  }, [addingPeep, map])

  useListeners([
    ['ADD_PEEP', async (store, { payload }) => {
      const {
        place,
        location
      } = payload

      const peep = {
        place,
        location,
      }

      setButtonDisabled(true)

      try {
        const createPeep = store.contexts.functions.httpsCallable('createPeep')
        const { data: id } = await createPeep(peep)

        store.mutate(state => {
          state.peeps.push({
            ...peep,
            name: peep.place.name,
            id,
            position: {
              lng: peep.location.longitude,
              lat: peep.location.latitude,
            },
          })
        })
      } catch (e) {
        if (e.code === 'already-exists') {
          store.mutate(state => {
            state.errors.push({
              code: e.code,
              date: Date.now(),
            })
          })
        }
      }

      // closing modal in all cases
      store.mutate(state => {
        state.addingPeep = undefined
      })

      setButtonDisabled(false)
    }],
  ])

  return (
    <div
      className={cn(
        'add-peep-background',
        {
          'add-peep-show': (!!addingPeep && places.length > 0) // TODO: when places are not there, add a loading indicator instead in the modal
        },
      )}
      onClick={onHide}
    >
      <div
        className="add-peep-card"
        onClick={e => e.stopPropagation()}
      >
        <select>
          {places.map(place => (
            <option
              onClick={() => setSelectedPlace(place)}
              key={place.label}
            >
              {place.label}
            </option>
          ))}
        </select>

        <button
          onClick={() => dispatch({ type: 'ADD_PEEP', payload: selectedPlace })}
          disabled={buttonDisabled}
        >
          Add
        </button>
      </div>
    </div>
  )
}

export default AddPeep
