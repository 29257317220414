import React, { useRef, useEffect, useState } from 'react'
import cn from 'classnames'
import { useListeners, useStateAt } from '@myrtille/react'
import './header.css'

const MINIMUM_PRINT_TIME_MS = 1500

const Header = () => {
  const mountTime = useRef(Date.now())
  const peepSelected = useRef(false)
  const [hide, setHide] = useState(false)

  const addingPeep = useStateAt('addingPeep')

  useListeners([
    ['SET_CENTER',
      () => {
        if (!peepSelected.current && mountTime.current + MINIMUM_PRINT_TIME_MS > Date.now()) return
        setHide(true)
      }
    ],
    ['SELECT_PEEP',
      (_, action) => {
        peepSelected.current = (action.payload !== undefined)
        setHide(!peepSelected.current)
      }
    ],
  ])

  useEffect(() => {
    mountTime.current = Date.now()
  }, [])

  return (
    <h1
      className={cn('header', { 'header-opened': !addingPeep && !hide })}
    >
      Peeps
    </h1>
  )
}

export default Header
