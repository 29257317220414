import createStore from '@myrtille/mutate'

const store = createStore({
  peeps: [],
  knownPeepsByHashes: {}, // for caching purpose
  selected: undefined,
  addingPeep: undefined,
  errors: [],
})

window.store = store

export default store
