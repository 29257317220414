import geo from 'latlon-geohash'
import { firestoreGetIncludes } from '@peep/common'

export const fetchPeeps = async (store, { payload }) => {
  if (!payload || !payload.lat || !payload.lng) return

  const latitude = (typeof payload.lat === 'function' ? payload.lat() : payload.lat)
  const longitude = (typeof payload.lng === 'function' ? payload.lng() : payload.lng)

  const geoHash = geo.encode(latitude, longitude).slice(0, 5)
  const geoHashes = Object.values(geo.neighbours(geoHash)).concat(geoHash)

  // little optimisation avoiding refetching for nothing
  if (geoHash === store.getState().center) return

  // if center changed, we remove older peeps before fetching new ones
  const knownPeepsByHashes = Object.fromEntries(
    Object.entries(store.getState().knownPeepsByHashes)
      .filter(([hash]) => geoHashes.includes(hash))
  )
  store.mutate((state) => {
    state.knownPeepsByHashes = knownPeepsByHashes
    state.center = geoHash
    state.peeps = Object.values(knownPeepsByHashes).flat()
  })

  const collection = store.contexts.db.collection('peeps')

  geoHashes.forEach(hash => {
    // we already know markers for this hash
    // so we don't request them
    if (knownPeepsByHashes[hash]) return

    // in other cases we request them
    firestoreGetIncludes(collection)('location.geoHash', hash)
      .then(snapshot => {
        const peeps = snapshot.docs.map((doc) => {
          const { location = {}, ...data } = doc.data()

          return {
            id: doc.id,
            ...data,
            position: {
              lat: location.latitude,
              lng: location.longitude,
            },
          }
        })

        store.mutate(state => {
          state.knownPeepsByHashes[hash] = peeps
          peeps.forEach(peep => {
            if (state.peeps.find(p => p.id === peep.id)) return
            state.peeps.push(peep)
          })
        })
       })
  })
}

export const selectPeep = (store, { payload }) => {
  store.mutate(state => state.selected = payload)
}

export const updateFirestoreReview = (store, { payload }) => {
  const peep = store.contexts.db.collection('peeps').doc(store.getState().selected)
  peep.update({
    [`reviews.${payload}`]: store.contexts.firebase.firestore.FieldValue.increment(1)
  })
}

export const updateReview = (store, { payload }) => {
  store.mutate(state => {
    const peep = state.peeps.find(peep => peep.id === state.selected)

    if (!peep.reviews) peep.reviews = {}
    if (!peep.reviews[payload]) peep.reviews[payload] = 0
    peep.reviews[payload] += 1
  })
}
