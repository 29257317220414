import React, { useRef, useState } from 'react'
import useGeolocation from 'react-use/lib/useGeolocation'
import { useListeners } from '@myrtille/react'
import { Marker } from '../map'
import './myLocation.css'

const icon = {
  url: "/me.png",
  scaledSize: { width: 14, height: 14 },
  origin: { x: 0, y: 0 },
}

const MyLocation = ({ map }) => {
  const firstLocationDispatched = useRef(false)
  const previousLocation = useRef()
  const [tracked, setTracked] = useState(true)
  const geolocation = useGeolocation({
    enableHighAccuracy: true,
    maximumAge: 500,
  })

  useListeners([
    ['SET_CENTER', () => setTracked(false)],
  ])

  if (geolocation.loading) return null
  if (geolocation.latitude === undefined) return null
  if (geolocation.longitude === undefined) return null

  const mapLocation = () => ({
    lng: geolocation.longitude,
    lat: geolocation.latitude,
  })

  if (!firstLocationDispatched.current) {
    firstLocationDispatched.current = true
    map.setCenter(mapLocation())
    setTracked(true)
  }

  if (
    previousLocation.current
    && tracked
    && (
      previousLocation.current.longitude !== geolocation.longitude
      || previousLocation.current.latitude !== geolocation.latitude
    )
  ) {
    previousLocation.current = geolocation
    map.setCenter(mapLocation())
  }

  const onTrackClick = () => {
    previousLocation.current = geolocation
    map.setCenter(mapLocation())
    setTracked(old => !old)
  }


  return (
    <>
      <Marker
        map={map}
        position={mapLocation()}
        icon={icon}
      />

      <div
        className="tracker"
        onClick={onTrackClick}
      >
        <img
          alt=""
          src="/tracked.png"
          className="tracker-background"
        />
        {tracked && (
          <img
            alt="is tracked"
            src="/me.png"
            className="tracker-yes"
          />
        )}
      </div>
    </>
  )
}

export default MyLocation
