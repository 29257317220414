import React, { useEffect, useRef, useState, Children, cloneElement } from 'react'

const Map = ({
  className,
  style,
  token,
  center,
  gestureHandling,
  zoom,
  libraries = '',
  disableZoomControl,
  disableMapTypeControl,
  disableScaleControl,
  disableStreetViewControl,
  disableRotateControl,
  disableFullscreenControl,
  children,
  onClick,
  onMouseDown,
  onLongPress,
  onCenterChanged,
}) => {
  const longPressTimer = useRef()
  const mapDiv = useRef()
  const olderToken = useRef()
  const olderCenter = useRef()
  const initFunctionName = useRef('initMap')
  const scriptElm = useRef()
  const [map, setMap] = useState()

  useEffect(() => {
    if (!mapDiv.current) return

    // optimisation, we don't re-init the map if the token doesn't change
    if (olderToken.current === token) return

    // find an init function name that doesn't already exists
    let retry = 0
    while (window[initFunctionName.current] !== undefined) initFunctionName.current = `initMap${retry++}`

    window[initFunctionName.current] = () => {
      setMap(new window.google.maps.Map(
        mapDiv.current,
        {
          center,
          zoom,
          gestureHandling,
          zoomControl: !disableZoomControl,
          mapTypeControl: !disableMapTypeControl,
          scaleControl: !disableScaleControl,
          streetViewControl: !disableStreetViewControl,
          rotateControl: !disableRotateControl,
          fullscreenControl: !disableFullscreenControl,
        }
      ))
    }

    const script = window.document.createElement('script')
    script.src = `https://maps.googleapis.com/maps/api/js?key=${token}&&libraries=${libraries}&callback=${initFunctionName.current}`
    script.async = true
    script.defer = true

    window.document.body.appendChild(script)

    // for later optimisation, we mark the map initialised for the given current token
    olderToken.current = token

    // for later demunting
    scriptElm.current = script
  }, [
    mapDiv,
    token,
    center,
    zoom,
    libraries,
    gestureHandling,
    disableZoomControl,
    disableMapTypeControl,
    disableScaleControl,
    disableStreetViewControl,
    disableRotateControl,
    disableFullscreenControl,
  ]) // TODO: try removing center/zoom/gestureHandling and look at warning

  useEffect(() => {
    return () => {
      if (scriptElm.current) window.document.body.removeChild(scriptElm.current)
      delete window[initFunctionName.current]
      delete window.google.maps
    }
  }, [])

  useEffect(() => {
    if (!map) return
    if (
      olderCenter.current
      && center.lat === olderCenter.current.lat
      && center.lng === olderCenter.current.lng
    ) return

    olderCenter.current = center
    map.setCenter(center)
  }, [map, center])

  useEffect(() => {
    if (!map || !onClick) return

    let timer
    let event
    let listeners = []

    listeners = listeners.concat(map.addListener('mousedown', (e) => {
      event = e
      timer = Date.now()
    }))

    listeners = listeners.concat(map.addListener('mouseup', () => {
      if (longPressTimer.current) clearTimeout(longPressTimer.current)
      if (timer + 500 > Date.now()) onClick(event)
    }))

    return () => {
      if (window.google.maps) {
        listeners.forEach((listener) => {
          window.google.maps.event.removeListener(listener)
        })
      }
    }
  }, [map, onClick])

  useEffect(() => {
    if (!map || !onMouseDown) return

    const listener = map.addListener('mousedown', onMouseDown)

    return () => {
      if (window.google.maps) window.google.maps.event.removeListener(listener)
    }
  }, [map, onMouseDown])

  useEffect(() => {
    if (!map || !onLongPress) return

    let listeners = []

    listeners = listeners.concat(map.addListener('mousedown', (e) => {
      if (longPressTimer.current) clearTimeout(longPressTimer.current)

      longPressTimer.current = setTimeout(() => onLongPress(e), 500)
    }))

    listeners = listeners.concat(map.addListener('mouseup', () => {
      if (longPressTimer.current) clearTimeout(longPressTimer.current)
    }))

    return () => {
      if (window.google.maps) {
        listeners.forEach((listener) => {
          window.google.maps.event.removeListener(listener)
        })
      }
    }
  }, [map, onLongPress])

  useEffect(() => {
    if (!map || !onCenterChanged) return

    const listener = map.addListener('center_changed', () => {
      if (longPressTimer.current) clearTimeout(longPressTimer.current)

      onCenterChanged(map.getCenter())
    })

    return () => {
      if (window.google.maps) window.google.maps.event.removeListener(listener)
    }
  }, [map, onCenterChanged])

  return (
    <>
      <div style={style} className={className} ref={mapDiv} />
      {map && Children.map(children, elm => elm && cloneElement(elm, { map }))}
    </>
  )
}

export default Map
