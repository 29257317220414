import { useEffect, useRef } from 'react'

const defaultPosition = {}

const Marker = ({ position = defaultPosition, map, icon, onClick }) => {
  const marker = useRef()

  useEffect(() => {
    if (!map) return
    if (marker.current) return

    marker.current = new window.google.maps.Marker({
      position,
      map,
      icon,
      // animation: window.google.maps.Animation.DROP,
    })

    let listener
    if (onClick) marker.current.addListener('click', onClick)

    return () => {
      if (listener && window.google.maps) window.google.maps.event.removeListener(listener)
    }
  }, [position, map, icon, onClick])

  useEffect(() => {
    return () => {
      if (!marker.current) return null

      marker.current.setMap(null)
    }
  }, [])

  useEffect(() => {
    if (!marker.current) return

    marker.current.setPosition({
      lat: position.lat,
      lng: position.lng,
    })
  }, [position.lat, position.lng])

  // TODO: add useEffect for icon and onClick so we update references in google marker

  return null
}

export default Marker
