exports.getAddressComponent = getAddressComponent;

function getAddressComponent(geoPlace, type) {
  if (!geoPlace || !geoPlace.address_components) return undefined;
  var component = geoPlace.address_components.find(function (_ref) {
    var _ref$types = _ref.types,
        types = _ref$types === void 0 ? [] : _ref$types;
    return types.includes(type);
  });
  if (!component) return undefined;
  if (component.long_name) return component.long_name;
  if (component.short_name) return component.short_name;
  return undefined;
}

exports.getSimplifiedAddress = getSimplifiedAddress;

function getSimplifiedAddress(geoPlace) {
  var streetNumber = getAddressComponent(geoPlace, 'street_number') || '';
  var route = getAddressComponent(geoPlace, 'route') || '';
  return "".concat(streetNumber, " ").concat(route).trim();
}

exports.firestoreGetIncludes = firestoreGetIncludes;

function firestoreGetIncludes(collection) {
  return function (path, strSearch) {
    var strlength = strSearch.length;
    var strFrontCode = strSearch.slice(0, strlength - 1);
    var strEndCode = strSearch.slice(strlength - 1, strSearch.length);
    var startcode = strSearch;
    var endcode = strFrontCode + String.fromCharCode(strEndCode.charCodeAt(0) + 1);
    return collection.where(path, '>=', startcode).where(path, '<', endcode).get();
  };
}