import React, { useState, useEffect } from 'react'
import { useStateAt } from '@myrtille/react'
import ReviewButton from '../reviewButton'
import './review.css'

const Review = ({ code }) => {
  const noCode = `no-${code}`

  const peeps = useStateAt('peeps')
  const selectedId = useStateAt('selected')
  const [reviews, setReviews] = useState({
    [code]: 0,
    [noCode]: 0,
  })

  useEffect(() => {
    const peep = peeps.find(({ id }) => id === selectedId)
    if (!peep) {
      setReviews({
        [code]: 0,
        [noCode]: 0,
      })

      return
    }

    const {
      reviews = {},
    } = peep;

    setReviews({
      [code]: reviews[code] || 0,
      [noCode]: reviews[noCode] || 0,
    })
  }, [code, noCode, peeps, selectedId])

  let percent = (reviews[code] / (reviews[noCode] + reviews[code]))
  if (Number.isNaN(percent)) percent = 0.5
  percent = (
    100
    * Math.max(
      0.02,
      Math.min(
        0.98,
        percent,
      ),
    )
  )

  return (
    <div className="review-line">
      <ReviewButton
        count={reviews[noCode]}
        percent={percent}
      >
        {noCode}
      </ReviewButton>

      <div className="review-indicator">
        <div
          className="review-point"
          style={{
            left: `${percent}%`
          }}
        />
        <div className="review-percent">
        </div>
      </div>

      <ReviewButton
        count={reviews[code]}
        percent={percent}
      >
        {code}
      </ReviewButton>
    </div>
  )
}

export default Review
