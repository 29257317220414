import React, { useEffect, useRef, useState } from 'react'
import { useStateAt } from '@myrtille/react'
import cn from 'classnames'
import './errors.css'

const TIMEOUT = 5000

const Errors = () => {
  const timer = useRef()
  const rawErrors = useStateAt('errors')
  const [errors, setErrors] = useState([])

  useEffect(() => {
    if (timer.current) clearTimeout(timer.current)
    const printable = ({ date }) => date + TIMEOUT > Date.now()

    const errorsToPrint = rawErrors.filter(printable)
    const setWithPrintable = () => {
      setErrors(rawErrors
        .map(error => ({ ...error, print: printable(error) }))
        .reverse()
      )
    }

    setWithPrintable()

    if (errorsToPrint.length > 0) {
      timer.current = setTimeout(setWithPrintable, TIMEOUT)
    }
  }, [rawErrors])

  return (
    <ul className="errors">
      {errors.map(({ date, code, print }) => (
        <li
          key={date}
          className={cn('error', { 'error-print': !!print })}
        >
          {code}
        </li>
      ))}
    </ul>
  )
}

export default Errors
