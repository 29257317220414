import React, { useState, createContext, useEffect } from 'react'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/functions'

const FirebaseContext = createContext()

const productionConfig = {
  apiKey: "AIzaSyDcBXNsf84mI13y-_i5N3CINUYkw4Y3V7I",
  authDomain: "peep-1da18.firebaseapp.com",
  databaseURL: "https://peep-1da18.firebaseio.com",
  projectId: "peep-1da18",
  storageBucket: "peep-1da18.appspot.com",
  messagingSenderId: "1059701296568",
  appId: "1:1059701296568:web:bfd8a1d2be3cac04"
}

const devConfig = {
  apiKey: 'AIzaSyDcBXNsf84mI13y-_i5N3CINUYkw4Y3V7I',
  authDomain: 'localhost',
  projectId: 'peep-1da18',
}

export const FirebaseProvider = ({ children }) => {
  const [state, setState] = useState({})

  useEffect(() => {
    firebase.initializeApp(process.env.NODE_ENV === 'production' ? productionConfig : devConfig)

    const db = firebase.firestore()
    const functions = firebase.app().functions('europe-west1')

    setState({ db, functions })
  }, [])

  return (
    <FirebaseContext.Provider value={state}>
      {children}
    </FirebaseContext.Provider>
  )
}

export default FirebaseContext
