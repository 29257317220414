import React, { useState, useEffect } from 'react'
import cn from 'classnames'
import { useStateAt } from '@myrtille/react'
import Review from './review'
import ReviewButton from './reviewButton'
import './panel.css'

const REVIEW_TYPES = ['great', 'freeofcharge', 'a11y', 'nursery']

const Panel = ({ onClose }) => {
  const [peep, setPeep] = useState()
  const [collapsed, setCollapsed] = useState(true)
  const peeps = useStateAt('peeps')
  const selectedId = useStateAt('selected')

  const {
    id,
    name,
    public: isPublic,
  } = (peep || {})

  useEffect(() => {
    setPeep(peeps.find(({ id }) => id === selectedId))
  }, [peeps, selectedId])

  useEffect(() => {
    setCollapsed(true)
  }, [id])

  const onToggleCollapse = () => {
    setCollapsed(value => !value)
  }

  return (
    <div
      className={cn(
        'panel',
        {
          'panel-public': isPublic,
          'panel-opened': !!id,
          'panel-big-opened': !collapsed,
        },
      )}
    >
      <button
        className="panel-collapse"
        onClick={onToggleCollapse}
      >
        =
      </button>

      <button
        className="panel-close"
        onClick={onClose}
      >
        x
      </button>

      <div className="panel-infos">
        <h1 className="panel-name">
          {name || (isPublic ? 'Public' : 'Unamed')}
        </h1>
      </div>

      <div className="panel-reviews">
        {REVIEW_TYPES.map(code => (
          <Review
            key={code}
            code={code}
          />
        ))}
      </div>

      <div className="panel-report">
          <ReviewButton
            className="panel-review-does-not-exist"
            hideCount
          >
            does-not-exist
          </ReviewButton>
      </div>
    </div>
  )
}

export default Panel
