import React from 'react'
import { ReactComponent as Great } from './icons/great.svg'
import { ReactComponent as NoGreat } from './icons/no-great.svg'
import { ReactComponent as A11y } from './icons/a11y.svg'
import { ReactComponent as NoA11y } from './icons/no-a11y.svg'
import { ReactComponent as Nursery } from './icons/nursery.svg'
import { ReactComponent as NoNursery } from './icons/no-nursery.svg'
import { ReactComponent as FreeOfCharge } from './icons/freeofcharge.svg'
import { ReactComponent as NoFreeOfCharge } from './icons/no-freeofcharge.svg'

const svgProps = (delta) => ({
  width: "2em",
  height: "2em",
  style: {
    transition: 'transform 0.2s linear 0s',
    transform: `scale(${1 + (delta / 100)})`
  },
})

export default ({ code, percent }) => {
  const delta = percent - 50

  switch (code) {
    case 'great': return <Great {...svgProps(delta)} />
    case 'no-great': return <NoGreat {...svgProps(-delta)} />
    case 'a11y': return <A11y {...svgProps(delta)} />
    case 'no-a11y': return <NoA11y {...svgProps(-delta)} />
    case 'nursery': return <Nursery {...svgProps(delta)} />
    case 'no-nursery': return <NoNursery {...svgProps(-delta)} />
    case 'freeofcharge': return <FreeOfCharge {...svgProps(delta)} />
    case 'no-freeofcharge': return <NoFreeOfCharge {...svgProps(-delta)} />
    case 'does-not-exist': return <div>Does not exists</div>
    default: return <div>{code}</div>
  }
}
