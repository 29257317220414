import React, { useEffect, useContext } from 'react'
import firebase from 'firebase/app'
import { provider, useDispatch, useListeners, useStore, useStateAt } from '@myrtille/react'
import store from './store'
import Map, { Marker } from './map'
import FirebaseContext from './contexts/firebase'
import Header from './header'
import Panel from './panel'
import AddPeep from './addPeep'
import MyLocation from './myLocation'
import Errors from './errors'
import listeners from './app.listeners'
import './app.css'

function App() {
  const { db, functions } = useContext(FirebaseContext)
  const store = useStore()
  const dispatch = useDispatch()

  useListeners(listeners)

  const onLongPress = (event) => {
    store.mutate((state) => {
      state.addingPeep = event.latLng
      state.selected = undefined
    })
  }

  const selectPeep = peep => dispatch({ type: 'SELECT_PEEP', payload: peep.id })
  const setCenter = center => dispatch({ type: 'SET_CENTER', payload: center })

  const peeps = useStateAt('peeps')

  useEffect(() => {
    if (!db || !functions) return

    store.contexts.db = db
    store.contexts.functions = functions
    store.contexts.firebase = firebase

    dispatch('FETCH_PEEPS')
  }, [db, functions, store, dispatch])

  return (
    <div className="app">
      <Map
        className="google-map"
        token="AIzaSyDcBXNsf84mI13y-_i5N3CINUYkw4Y3V7I"
        gestureHandling="greedy"
        libraries="places"
        center={{ lat: 47.4819411, lng: -0.5980396 }} // Nantes: { lat: 47.2161421, lng: -1.5507366 }}
        zoom={15}
        onLongPress={onLongPress}
        onCenterChanged={setCenter}
        disableScaleControl
        disableFullscreenControl
        disableZoomControl
        disableRotateControl
        disableMapTypeControl
      >
        {peeps.map(peep => (
          <Marker
            key={peep.id}
            position={peep.position}
            icon={peep.public ? '/favicon.ico' : undefined}
            onClick={() => selectPeep(peep)}
          />
        ))}

        <MyLocation />
        <AddPeep />
      </Map>

      <Header />
      <Panel
        onClose={selectPeep}
      />

      <Errors />
    </div>
  )
}

export default provider(store)(App)
