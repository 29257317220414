import React from 'react'
import cn from 'classnames'
import { useDispatch } from '@myrtille/react'
import ReviewIcon from '../reviewIcon'
import './reviewButton.css'

const getAriaLabel = (code) => {
  switch (code) {
    case 'great': return 'Great!'
    case 'no-great': return 'Beurk'
    case 'does-not-exist': return 'Does not exists'
    default: return 'Sorry we forgot to set a label'
  }
}

const ReviewButton = ({
  className,
  hideCount = false,
  count = 0,
  percent = 0,
  children
}) => {
  const dispatch = useDispatch()

  const handleClick = () => dispatch({ type: 'ADD_REVIEW', payload: children })

  return (
    <button
      className={cn(className, 'review')}
      onClick={handleClick}
    >
      <div className="review-text">
        <span role="img" aria-label={getAriaLabel(children)}>
          <ReviewIcon percent={percent} code={children} />
        </span>
      </div>

      {hideCount || (
        <div className="review-count">
          {count}
        </div>
      )}
    </button>
  )
}

export default ReviewButton
